import slugify from 'slugify'

export default class TOC {
  constructor () {
    this.els = {
      container: document.querySelector('.m-toc'),
      pagebuilderContainer: document.querySelector('.m-pagebuilder')
    }

    this.headings = null
    this.getHeadings()
    this.createHTML()
  }

  getHeadings () {
    this.headings = this.els.pagebuilderContainer.querySelectorAll('h2, h3')
  }

  createHTML () {
    let template = '<ul>'
    let currentLevel = 1
    this.headings.forEach((el, index) => {
      if (currentLevel === 1 && el.tagName === 'H3') {
        template += '<ul><li>'
        currentLevel = 2
      } else if (currentLevel === 2 && el.tagName === 'H2') {
        template += '</li></ul><li>'
        currentLevel = 1
      } else if (index > 0) {
        template += '</li><li>'
      } else {
        template += '<li>'
      }
      const title = el.innerHTML
      const slug = slugify(title, { lower: true, strict: true })
      el.setAttribute('id', slug)
      el.classList.add('anchor-item')
      template += `<a href="#${slug}">${title}</a>`
    })

    if (currentLevel === 2) {
      template += '</ul></li>'
    }

    template += '</ul>'

    this.els.container.innerHTML = template
  }

  destroy () {
    this.els.container.innerHTML = ''
    this.headings.forEach(el => {
      el.removeAttribute('id')
    })
    this.headings = null
  }
}
