export const isMobile = () => {
  return window.innerWidth <= 600
}

export const isTablet = () => {
  return window.innerWidth >= 600 && window.innerWidth < 990
}

export const isDesktop = () => {
  return window.innerWidth >= 990
}
