import elementInViewport from 'element-in-viewport'

export default class ImageLazy {
  constructor (el, options) {
    const defaultOptions = {
      revealEffect: true,
      loadedClass: '-loaded'
    }
    this.el = el
    this.onLoaded = this.onLoaded.bind(this)

    this.options = { ...defaultOptions, ...options }

    if (!this.el.dataset.src && !this.el.dataset.srcset) {
      throw new Error('[ImageLazyLoading] Missing data attribute src or srcset to lazy load image')
    }

    elementInViewport(this.el)
      .then(() => {
        this.loadSrc()
      })
  }

  loadSrc () {
    this.el.addEventListener('load', this.onLoaded)
    this.el.srcset = this.el.dataset.srcset
    this.el.src = this.el.dataset.src
  }

  onLoaded () {
    this.el.removeEventListener('load', this.onLoaded)
    this.el.classList.add(this.options.loadedClass)
  }

  destroy () {
  }
}
