import { isDesktop } from '@js/utils/Helpers'
import throttle from 'lodash/throttle'

export default class LeftNav {
  constructor () {
    console.log('===> new left nav')
    this.els = {
      generalContainer: null,
      navOpenedContainer: null,
      navCollapsedContainer: null,
      btnToggle: null,
      collapsedBtnToggle: null
    }
    this.isNavOpened = true // @todo: persist preference
    this.isPristine = true
    this.openNav = this.openNav.bind(this)
    this.closeNav = this.closeNav.bind(this)
    this.toggleNav = this.toggleNav.bind(this)
    this.onResize = throttle(this.onResize.bind(this), 500)

    this.init()
  }

  init () {
    console.log('===> left nav init')
    this.els.generalContainer = document.querySelector('#l-generalContainer')
    this.els.navOpenedContainer = document.querySelector('#l-leftNav')
    this.els.navCollapsedContainer = document.querySelector('#l-leftNav-collapsed')
    this.els.btnToggle = this.els.navOpenedContainer.querySelector('.l-toggleItem')
    this.els.collapsedBtnToggle = this.els.navCollapsedContainer.querySelector('.l-toggleItem')

    this.addListeners()
    this.onResize()
  }

  addListeners () {
    window.addEventListener('resize', this.onResize)
    this.els.btnToggle.addEventListener('click', this.toggleNav)
    this.els.collapsedBtnToggle.addEventListener('click', this.toggleNav)
  }

  removeListeners () {
    window.removeEventListener('resize', this.onResize)
    this.els.btnToggle.removeEventListener('click', this.toggleNav)
    this.els.collapsedBtnToggle.removeEventListener('click', this.toggleNav)
  }

  toggleNav () {
    this.isPristine = false
    if (this.isNavOpened) {
      this.closeNav()
    } else {
      this.openNav()
    }
  }

  openNav () {
    this.isNavOpened = true
    this.els.generalContainer.classList.add('is-nav-open')
    this.els.generalContainer.classList.remove('is-nav-close')

    // Accessbility Checks for Accessible Rich Internet Applications (ARIA) compliance

    this.els.btnToggle.setAttribute('aria-expanded', 'true')
    this.els.collapsedBtnToggle.setAttribute('aria-expanded', 'false')
    this.els.navOpenedContainer.setAttribute('aria-hidden', 'false')
    this.els.navCollapsedContainer.setAttribute('aria-hidden', 'true')

    // If on mobile/tablet, we cancel the navbar hidden state toggled above since always shown on mobile/tablet (css)

    if (!isDesktop()) {
      this.els.navCollapsedContainer.setAttribute('aria-hidden', 'false')
    }
  }

  closeNav () {
    this.isNavOpened = false
    this.els.generalContainer.classList.add('is-nav-close')
    this.els.generalContainer.classList.remove('is-nav-open')

    // Accessbility Checks for Accessible Rich Internet Applications (ARIA) compliance

    this.els.btnToggle.setAttribute('aria-expanded', 'false')
    this.els.collapsedBtnToggle.setAttribute('aria-expanded', 'true')
    this.els.navOpenedContainer.setAttribute('aria-hidden', 'true')
    this.els.navCollapsedContainer.setAttribute('aria-hidden', 'false')
  }

  onResize () {
    if (this.isPristine) {
      if (!isDesktop() && this.isNavOpened) {
        this.closeNav()
      } else if (isDesktop() && !this.isNavOpened) {
        this.openNav()
      }
    }
  }

  destroy () {
    console.log('===> left nav destroy')
    this.removeListeners()
  }
}
