import Glightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.css'

export default class Gallery {
  constructor (options) {
    const defaultOptions = {
      selector: '*[data-gallery]',
      touchNavigation: true,
      loop: false,
      autoplayVideos: false
    }

    this.options = { ...defaultOptions, options }

    this.glightbox = new Glightbox(this.options)
  }

  destroy () {
    this.glightbox.destroy()
  }
}
