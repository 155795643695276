import { TweenMax } from 'gsap/all'
import throttle from 'lodash/throttle'

export default class Scrolltop {
  constructor () {
    this.scrollToTopBtn = document.querySelector('.m-scroll-top')

    if (this.scrollToTopBtn) {
      this.rootElement = document.documentElement
      this.scrollToTop = this.scrollToTop.bind(this)
      this.showHide = throttle(this.showHide.bind(this), 50)
      this.toggleRatio = 20
      this.scrollTotal = this.rootElement.scrollHeight - this.rootElement.clientHeight

      this.scrollToTopBtn.addEventListener('click', this.scrollToTop)
      window.addEventListener('scroll', this.showHide) // we need to be careful on scroll listener (add throttle)
    }
  }

  isFooterVisible () {
    const footer = document.querySelector('footer')
    const rect = footer.getBoundingClientRect()
    const elemTop = rect.top
    const elemBottom = rect.bottom
    const isVisible = elemTop < window.innerHeight && elemBottom >= 0
    return isVisible
  }

  showHide () {
    if (this.isFooterVisible()) {
      // show button above footer
      TweenMax.to(this.scrollToTopBtn, 0.35, {
        display: 'block',
        opacity: '0.75',
        position: 'relative'
      })
    } else if (window.scrollY > this.toggleRatio) {
      // fix button at bottom page
      TweenMax.to(this.scrollToTopBtn, 0.35, {
        display: 'block',
        opacity: '0.75',
        position: 'fixed'
      })
    } else {
      // hide button
      TweenMax.to(this.scrollToTopBtn, 0.35, {
        opacity: 0,
        display: 'none'
      })
    }
  }

  scrollToTop () {
    // scroll to top logic
    this.rootElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  destroy () {
    if (this.scrollToTopBtn) {
      this.scrollToTopBtn.removeEventListener('click', this.scrollToTop)
      document.removeEventListener('scroll', this.showHide)
    }
  }
}
