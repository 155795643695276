import '@less/app'
import '@sass/app'
import App from './App'

// Enable HRM
let app = null

if (module.hot) {
  module.hot.accept('./App.js', () => {
    if (module.hot.data) {
      ({ app } = module.hot.data)
      app.destroy()

      app = new App()
      app.init()
    }
  })
}
if (!app) {
  app = new App() // if no application so far, start it
  document.addEventListener('DOMContentLoaded', () => app.init())
}

if (module.hot) {
  module.hot.dispose((data) => {
    // on disposal of the old version (before reload), pass the old application to the new version
    data.app = app; // eslint-disable-line
  })
}
// end - Enable HRM

// debug
window.app = app
