import LeftNav from '@templates/layout/navigation/main/index'
import ImageLazy from '@templates/components/image/index'
import Gallery from '@templates/components/gallery/index'
import TOC from '@templates/components/toc/index'
import Scrolltop from '@templates/components/scroll-top'
import CookiesBannerManager from '@web-nfb/frontend-static/design-system/script/elements/CookiesBannerManager.ts'
import AccordionManager from '@web-nfb/frontend-static/design-system/script/elements/AccordionManager.ts'

export default class App {
  constructor () {
    this.leftNav = new LeftNav()
    this.cookiesBannerManager = new CookiesBannerManager()
    this.accordionManager = new AccordionManager({
      selector: '[data-ui-el="nfb-accordion-item-trigger"]',
      autoInit: false
    })
  }

  init () {
    console.log('===> app init')
    this.initGallery()
    this.initTOC()
    this.initScrolltop()
    this.initImageLazy()
    this.cookiesBannerManager.init()
    this.accordionManager.init()
  }

  destroy () {
    console.log('===> app destroy')
    this.destroyGallery()
    this.destroyTOC()
    this.destroyImageLazy()
    this.destroyScrolltop()
    this.cookiesBannerManager?.destroy()
    this.accordionManager.destroy()
  }

  initTOC () {
    console.log('===> init toc')
    if (document.querySelector('.m-toc')) {
      this.toc = new TOC()
    }
  }

  destroyTOC () {
    if (this.toc) {
      this.toc.destroy()
    }
  }

  initGallery () {
    console.log('===> init gallery')
    this.gallery = new Gallery()
  }

  destroyGallery () {
    this.gallery.destroy()
    this.gallery = null
  }

  initScrolltop () {
    console.log('===> init scrolltop')
    this.Scrolltop = new Scrolltop()
  }

  destroyScrolltop () {
    this.Scrolltop.destroy()
  }

  initImageLazy () {
    const els = document.querySelectorAll('img.lazy')
    this.imagesLazy = []
    els.forEach(el => {
      this.imagesLazy.push(new ImageLazy(el))
    })
  }

  destroyImageLazy () {
    this.imagesLazy.forEach(item => item.destroy())
    this.imagesLazy = []
  }
}
